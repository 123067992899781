import InlineVideo from "@/components/help-center/custom-tags/InlineVideo.vue";

export const inlineVideo = {
  render: InlineVideo,
  attributes: {
    videoSource: {
      type: String,
    },
    marginStyles: {
      type: String,
    },
  },
};
